body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

canvas.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  max-width: 900px;
  height: 100% !important;
}

div.max-w-header {
  max-width: calc(100vw - 36px);
}

body > iframe {
  pointer-events: none;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.bg-fb-blue {
  background-color: #1877f2;
}

.social-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px;
}

.max-w-130 {
  max-width: 520px;
}

.w-15 {
  width: 60px;
}

div[role='button'] {
  cursor: default;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.min-h-90 {
  min-height: 360px;
}

.Toastify__toast-container {
  z-index: 100000000;
}
